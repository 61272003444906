import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import styles from "./index.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import { func, shape, object } from "prop-types";

class TravelIndex extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(1);
  }

  goForward = () => {
    navigate("/modules/travel/introduction/roger");
  };

  goBack = () => {};

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.openingText}>
            <p>
              Your family is super excited about traveling out of state to
              Disney World!
            </p>
          </div>
          <div className={styles.startImageContainer}>
            <Img fluid={this.props.data.family.childImageSharp.fluid} />
          </div>
          <div className={styles.endImageContainer}>
            <Img fluid={this.props.data.disney.childImageSharp.fluid} />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export const query = graphql`
  query {
    family: file(name: { eq: "family" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    disney: file(name: { eq: "disney-castle" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default connect(
  stateToProps,
  dispatchToProps
)(TravelIndex);
